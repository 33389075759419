import './assets/css/layout.css'
import './assets/css/header.css'
import './assets/css/button.css'
import './assets/css/box.css'
import './assets/css/question.css'
import './assets/css/illustration.css'
import './assets/css/icon.css'
import './assets/css/tooltip.css'

import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Footer } from './components/Footer.tsx'
import GlobalContext from './components/GlobalContext.tsx'
import { Header } from './components/Header.tsx'
import { Illustration } from './components/Illustration.tsx'
import { InitialPropositions } from './components/InitialPropositions.tsx'
import MyMarkdown from './components/Markdown.tsx'
import { Messages } from './components/Messages.tsx'
import { QuickFeedback } from './components/QuickFeedback.tsx'
import { Result } from './components/Result.tsx'
import { ResultLinks } from './components/ResultLinks.tsx'
import { SearchForm } from './components/SearchForm.tsx'
import { DEFAULT_SHORT_NAME, replaceLinks, UsedLinks } from './helpers.ts'
import { TypingMessageQueue } from './TypingMessageQueue.ts'
import { EventSourceMessage, useEventSource } from './useEventSource.ts'

interface HistoryResult {
  question: string
  answer?: string
  usedLinks?: UsedLinks[]
}

function App(props: { route?: string }) {
  const [userId, setUserId] = useState<string | null>(localStorage.getItem('uID') || sessionStorage.getItem('uID'))
  const [cID, setCId] = useState<string | null>(sessionStorage.getItem('cID')) // we keep it in session storage, because sometimes during dev it gets lost and this way, we always have one somehow during development
  const queryParams = new URLSearchParams(window.location.search)
  const [initialQuery] = useState<string | null>(queryParams.get('q'))
  const { mainLanguage, org } = useContext(GlobalContext)
  const [inputValue, setInputValue] = useState(queryParams.get('q') || '')
  const [sseUri, setSseUri] = useState('')

  const { close: eventSourceClose, start: eventSourceStart } = useEventSource()
  const { host } = useContext(GlobalContext)
  const getQueryFromLocation = useCallback(() => {
    // Use the value of the query parameter as needed
    const queryParams = new URLSearchParams(window.location.search)
    const queryFromLocation = queryParams.get('q')
    if (queryFromLocation) {
      setInputValue(queryFromLocation)
    }
  }, [])

  useEffect(() => {
    fetch(host + `/${org}/log`, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ referrer: document.referrer || null, initialQuery: initialQuery, uID: userId }),
    }).then(async response => {
      const user = await response.json()
      if (user && user.id) {
        localStorage.setItem('uID', user.id)
        setUserId(user.id)
        sessionStorage.setItem('cID', user.cID)
        setCId(user.cID)
      }
    })
  }, [initialQuery, host, org, userId])

  const { t } = useTranslation()
  const clearResults = useCallback(() => {
    setErzFunction(null)
    setOtherModel(false)
    setUsedLinksResult([])
    setLinksResult('')
    setMaybeBadRating(false)
  }, [])

  useEffect(() => {
    const handleLocationChange = () => {
      // Handle location change here
      getQueryFromLocation()
      clearResults()
      setHistoryResult([])
      setQueryResult('')
    }

    // Add event listener for location change
    window.addEventListener('popstate', handleLocationChange)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('popstate', handleLocationChange)
    }
  }, [clearResults, t, getQueryFromLocation])

  const [lastQuestion, setLastQuestion] = useState<string>('')
  const [queryValue, setQueryValue] = useState('')
  const [requestId, setRequestId] = useState<string | null>(null)
  const [disabledTimeout, setDisabledTimeout] = useState<number | undefined>(undefined)
  const [erzFunction, setErzFunction] = useState<{ query: string; function: string } | null>(null)

  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [historyResult, setHistoryResult] = useState<HistoryResult[]>([])
  const [queryResult, setQueryResult] = useState<string>('')
  const [linksResult, setLinksResult] = useState<string>('')
  const [usedLinksResult, setUsedLinksResult] = useState<UsedLinks[]>([])

  const [otherModel, setOtherModel] = useState<boolean>(false)
  const [maybeBadRating, setMaybeBadRating] = useState<boolean>(false)

  const questionRef = useRef(null)

  const [isLoading, setIsLoading] = useState(false)
  const setNewEventSource = useCallback(
    (queryValue: string, model: string, ft?: string) => {
      if (queryValue && org) {
        // the random part is just that we create a new event source when we submit...
        // maybe there are better solutions for this.
        const source = `${host}/${org}/assistant/sse?query=${encodeURIComponent(
          `${queryValue} chat:true`,
        )}&key=X9hL4Gp5W2D7eRtF&r=${Math.floor((new Date().getTime() / 1000) % 600)}${userId ? `&uID=${userId}` : ''}${
          cID ? `&cID=${cID}` : ''
        }&lang=${mainLanguage}${model !== DEFAULT_SHORT_NAME.MODEL_3_5_4k ? `&modelId=${model}` : ''}${
          ft ? `&ft=${ft}` : ''
        }`

        setSseUri(source)
      }
    },
    [cID, host, org, mainLanguage, userId],
  )

  function changeUrl(inputValue: string) {
    const searchParams = new URLSearchParams(window.location.search)

    if (searchParams.get('q') !== inputValue) {
      searchParams.set('q', inputValue)
      window.history.pushState({}, '', `${window.location.pathname}?${searchParams.toString()}`)
    }

    document.title = `${import.meta.env.VITE_SITE_NAME}: ${inputValue}`
  }

  const sendSubmit = (inputValue: string, model = DEFAULT_SHORT_NAME.MODEL_3_5_4k) => {
    const fetchData = async () => {
      clearResults()
      setNewEventSource(inputValue, model)

      const last = historyResult[historyResult.length - 1]
      if (last && queryResult && queryResult !== t('results.initial')) {
        last.answer = replaceLinks(queryResult, usedLinksResult)
        last.usedLinks = usedLinksResult
        setHistoryResult(historyResult => {
          historyResult[historyResult.length - 1] = last
          return historyResult
        })
      }

      const historyEntry: HistoryResult = { question: `**${t('answers.question')}:** ${inputValue}` }

      setHistoryResult(historyResult => {
        return historyResult.concat(historyEntry)
      })
      setLastQuestion(inputValue)

      setQueryResult(t('answers.oneMoment'))
      // make sure we set the input value to empty, after the push state was updated
      window.setTimeout(() => {
        document.querySelector('.results')?.scrollIntoView({ behavior: 'smooth', block: 'center' })
        setInputValue('')
      }, 1)
    }
    if (inputValue.trim() === '') return
    setSubmitDisabled(true)

    setQueryValue(inputValue)

    if (disabledTimeout) {
      clearTimeout(disabledTimeout)
    }
    setDisabledTimeout(
      window.setTimeout(() => {
        setSubmitDisabled(false)
      }, 5000),
    )
    changeUrl(inputValue)
    fetchData()
  }

  const parseMessageFromEventSource = useCallback(
    (parsedData: Record<string, any>, queue: TypingMessageQueue) => {
      try {
        if (parsedData.usedLinks) {
          setUsedLinksResult(linksResult => {
            return linksResult.concat(parsedData.usedLinks)
          })
        }
        if (parsedData.maybeBadRating) {
          setMaybeBadRating(true)
        }
        if (parsedData.response) {
          const answerEnd = () => {
            setSubmitDisabled(false)
            setIsLoading(false)
            setOtherModel(true)
            const qField = document.querySelector('#question') as HTMLInputElement
            if (qField?.focus) {
              qField.focus()
            }
            document.querySelector('.results')?.scrollIntoView({ behavior: 'smooth', block: 'center' })
          }
          if (parsedData.response === '__THIS_IS_THE_ANSWER_END__') {
            answerEnd()
            return
          }

          if (parsedData.response === '__THIS_IS_THE_END__') {
            eventSourceClose()
            queue.enqueue(parsedData.response)
            queue.setThisIsTheEnd()
            answerEnd()
            return
          }
          if (parsedData.response === '__CLR__') {
            document.querySelector('.results')?.scrollIntoView({ behavior: 'smooth', block: 'center' })
            setSubmitDisabled(false)
            queue.enqueue(parsedData.response)
            setOtherModel(false)
            return
          }

          queue.enqueue(parsedData.response)
          /*setQueryResult(queryResult => {
              return queryResult.concat(parsedData.response)
            })*/
        }
        if (parsedData.erz) {
          setErzFunction({ query: queryValue, function: JSON.stringify(parsedData.erz) })
        }
        if (parsedData.timetable) {
          setErzFunction({ query: queryValue, function: JSON.stringify(parsedData.timetable) })
        }
        if (parsedData.id) {
          setRequestId(parsedData.id)
        }

        if (parsedData.query) {
          document.querySelector('.results')?.scrollIntoView({ behavior: 'smooth', block: 'center' })

          setHistoryResult(historyResult => {
            const last = historyResult[historyResult.length - 1]
            if (last && queryValue.trim() !== parsedData.query.trim()) {
              historyResult[historyResult.length - 1].question = `**${t('answers.question')}:** ${queryValue} ${
                parsedData.query === '__NO_COMBINED_QUERY__' ? '' : `(${parsedData.query})`
              }`
              if (parsedData.query !== '__NO_COMBINED_QUERY__') {
                changeUrl(parsedData.query)
              }
            }
            return historyResult
          })
        }

        if (parsedData.model && parsedData.model.model === DEFAULT_SHORT_NAME.MODEL_3_5_16k) {
          setSubmitDisabled(false)
          setQueryResult(`Wir versuchen's mit längerem Kontext...`)
          if (parsedData.model.query) {
            setQueryValue(parsedData.model.query)
            setNewEventSource(parsedData.model.query, parsedData.model.model, parsedData.model.ft)
            setInputValue(parsedData.model.query)
          } else {
            setNewEventSource(queryValue, parsedData.model.model, parsedData.model.ft)
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    [eventSourceClose, queryValue, setNewEventSource],
  )

  useEffect(() => {
    let linksReceived = false
    try {
      if (!sseUri || !queryValue) {
        return
      }
      setIsLoading(true)
      const queue = new TypingMessageQueue(setQueryResult)
      const onerror = (e: Event) => {
        // for some strange reason, we get an error event before the server sends the __THIS_IS_THE_END__ event
        // in some cases. We don't need to show that error, after we received some links... The main result is here anyway
        // For example: Ask for "Wer ist Inka?" on WintiGPT....
        if (!linksReceived) {
          setQueryResult(queryResult => queryResult.concat(`${t('answers.serverConnectionError')}`))
        }
        console.log('SSE Error', e)
        eventSourceClose()
        setSubmitDisabled(false)
        setIsLoading(false)
        setOtherModel(false)
      }
      const onmessage = (event: EventSourceMessage) => {
        const parsedData = JSON.parse(event.data)

        if (parsedData.links) {
          setLinksResult(linksResult => {
            return linksResult.concat(parsedData.links)
          })
          linksReceived = true
        }

        parseMessageFromEventSource(parsedData, queue)
      }
      eventSourceStart(sseUri, onmessage, onerror)

      return () => {
        console.log('end')
        eventSourceClose()
      }
    } catch (error) {
      console.log(error)
    }
  }, [eventSourceClose, eventSourceStart, parseMessageFromEventSource, queryValue, sseUri, t])

  const changeModel = () => {
    sendSubmit(lastQuestion, DEFAULT_SHORT_NAME.MODEL_3_5_16k)
  }

  const [openDrawer, setOpenDrawer] = useState<string | null>(props.route || null)
  const handleMenuItemClick = (drawerName: string | ((prevState: string | null) => string | null)) => {
    if (typeof drawerName === 'string') {
      if (openDrawer === drawerName) {
        setOpenDrawer(null)
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        setOpenDrawer(drawerName)
      }
    } else {
      setOpenDrawer(prevState => prevState)
    }
    window.history.pushState({}, '', `/${drawerName}`)
  }

  return (
    <div className={`layout ${isLoading ? 'answer-is-loading' : ''} ${openDrawer ? 'drawer-is-open' : ''}`}>
      <Header
        inputValue={inputValue}
        requestId={requestId}
        userId={userId}
        handleMenuItemClick={handleMenuItemClick}
        openDrawer={openDrawer}
      />
      <Illustration />
      <main className="main">
        <>
          {historyResult.length > 0 && (
            <>
              {historyResult.map((result, index) => (
                <div key={`h_${index}`}>
                  <div key={`q_${index}`} className={'result__question'}>
                    <div className="container">
                      <MyMarkdown markdown={result.question} usedLinks={usedLinksResult}></MyMarkdown>
                    </div>
                  </div>
                  {result.answer && (
                    <div key={`a_${index}`} className={'result__answer'}>
                      <div className="container">
                        <MyMarkdown markdown={result.answer} usedLinks={result.usedLinks}></MyMarkdown>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </>
          )}
          <div className="container results">
            <Result
              queryResult={replaceLinks(queryResult, usedLinksResult) || (!isLoading ? t('results.initial') : '')}
              query={queryValue}
              erzFunction={erzFunction}
              setLinksResult={setLinksResult}
              userId={userId}
              isLoading={isLoading}
              usedLinksResult={usedLinksResult}
            />
          </div>
          <div className="container messages">
            <Messages userId={userId} />
          </div>
          <div className="container result-links">
            <ResultLinks
              linksResult={linksResult}
              maybeBadRating={maybeBadRating}
              otherModelButton={otherModel && !sseUri.includes(`modelId=${DEFAULT_SHORT_NAME.MODEL_3_5_16k}`)}
              quickFeedback={
                <QuickFeedback
                  questionRef={questionRef}
                  requestId={requestId}
                  userId={userId}
                  inputValue={inputValue}
                  changeModel={changeModel}
                  handleMenuItemClick={handleMenuItemClick}
                  openDrawer={openDrawer}
                />
              }
            />
          </div>
        </>
      </main>
      <div className="fixed-footer" ref={questionRef}>
        <div className="container">
          {!queryResult && (
            <>
              <InitialPropositions sendSubmit={sendSubmit} />
            </>
          )}
          <SearchForm
            setInputValue={setInputValue}
            inputValue={inputValue}
            sendSubmit={sendSubmit}
            submitDisabled={submitDisabled}
            setIsLoading={setIsLoading}
          />
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default App
