import i18n from 'i18next'
import LanguageDetector, { CustomDetector, DetectorOptions } from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

// if we want to always show one language as default, no matter what the browser says
// if you want to use the browser language, just remove the CustomDetector in the detection options
export const defaultLanguage = 'de'

const myDetector: CustomDetector = {
  name: 'myDetectorsName',

  lookup(options: DetectorOptions) {
    return (
      (options.lookupLocalStorage ? localStorage.getItem(options.lookupLocalStorage) : undefined) || defaultLanguage
    )
  },
}

const languageDetector = new LanguageDetector()
languageDetector.addDetector(myDetector)

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(languageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    detection: { order: ['myDetectorsName'] },
    debug: false,
    fallbackLng: defaultLanguage,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          header: {
            title: `${import.meta.env.VITE_SITE_NAME}`,
            slogan: `I am ${import.meta.env.VITE_SITE_NAME} and I know (almost) everything published on <1>${
              import.meta.env.VITE_MAIN_SITE_SHORT
            }</1>.`,
          },
          from: 'from',

          results: {
            initial: `
              Hello, I am ${import.meta.env.VITE_SITE_NAME} and I know – almost – everything published on ${
                import.meta.env.VITE_MAIN_SITE_SHORT
              }.
              Ask me anything about this wonderful city :)`,
            initialEmpty: ``,
          },
          question: {
            label: 'Your Question',
            placeholder: 'Ask me your question',
            buttonText: 'Send',
            microphoneLabel: 'Ask Question (with Microphone)',
            microphoneListening: 'Microphone: Hold down the button and speak',
          },
          proposition: {
            intro: 'Ask your question below or start with one of these examples:',
            example: [
              ['I want to move to Zurich. Make me a list of the administrative steps.'],
              ['What are the best nightclubs for electronic music in Zurich?'],
              ["Who is the city's mayor?"],
              ['How many residents does Zurich have?'],
              ['When are the school holidays?'],
              ['What is Zurich doing for climate protection?'],
              ['Where did Albert Einstein live in Zurich?'],
              ['Is the electricity in Zurich nuclear-free?'],
              ['Where can I rent a bicycle?'],
              ['I need a new passport, what can I do?'],
              ['When is the next cardboard collection on Quellenstrasse?'],
              ['How do I dispose of tires?'],
              ['Tell me about creative activities I can do with my children.'],
              ['Give me tips and tricks to save money on my tax return.'],
              ['Where can I order a debt collection register extract?'],
              ['When is the paper collection in Wiedikon?'],
              ['How can I order a parking card?'],
              ['I am moving to Zurich, where do I need to register?'],
              ['How do I get a residence confirmation?'],
              ['How do I enroll my child in daycare?'],
            ],
          },
          answers: {
            oneMoment: 'One moment please...',
            serverConnectionError: 'Connection to the server lost :( Please try again soon.',
            otherModelButton: 'Generate new response',
            wasItUseful: 'Was the answer useful?',
            yes: 'Yes',
            no: 'No',
            giveFeedback: 'Give Feedback',
            readHints: 'Read Hints and Limitations',
            letsTryToImprove: "Let's try to improve the answer!",
            improvePrompt: 'Refine your question and try again',
            thanksForFeedback: 'Thanks for your feedback!',
            unhappy: 'Unhappy with the answer?',
            question: 'Question',
            showRefs: 'Show all References',
            hideRefs: 'Minimize References',
          },
          hints: {
            title: 'Hints and Limitations',
            notesTitle: 'Hints',
            note1:
              'The bot does not remember previously asked questions. You need to enter the question again for refinements.',
            note2:
              'It is better to ask complete questions instead of just keywords. Sometimes, reformulating the question may result in a better answer.',
            note3:
              'Providing the current year (e.g., 2023) or asking more specific questions can yield more up-to-date answers.',
            note4: 'If no answer is received or the answer abruptly ends, it is best to try again.',
            note5:
              "We store your questions and answers with us so that we can improve the service over time. That's why we appreciate it if you give us feedback; it helps us tremendously. [Privacy Policy](/privacy)",
            note6:
              'All information is provided without warranty. Please verify the information on the official website of the City of Zurich.',
            limitationsTitle: 'Limitations',
            limitationsText: `${import.meta.env.VITE_SITE_NAME} knows (almost) everything that was published on <1>${
              import.meta.env.VITE_MAIN_SITE_SHORT
            }</1> on ${import.meta.env.VITE_DATE_EN}.`,
            disclaimerText: 'This app is not associated with the City of Zurich.',
          },
          contact: {
            title: 'Contact and Feedback',
            intro: `
              We continue to work to improve, expand, and regularly update ${import.meta.env.VITE_SITE_NAME}. 
              We therefore appreciate any feedback. Or even spreading the word if you are satisfied with ${
                import.meta.env.VITE_SITE_NAME
              }. `,
            emailLabel: 'Your E-Mail (optional):',
            messageLabel: 'Your message:',
            submitButton: 'Submit',
            feedbackReceived: 'Feedback received, thank you!',
            feedbackReceivedError: 'Error while sending feedback, please try the other means to reach us.',
            emailText: 'Oder senden Sie einsend ann email at chregu@liip.ch',
            moreContact: 'More contact options at',
          },
          footer: {
            credits: 'Powered by <1>Liip</1> — <3>Blog Post about the project</3>',
            disclaimer: `${import.meta.env.VITE_SITE_NAME} is not affiliated with the City of Zurich.`,
            selectLanguage: 'Select Language',
          },
        },
      },
      de: {
        translation: {
          header: {
            title: `${import.meta.env.VITE_SITE_NAME}`,
            slogan: `Ich bin ${import.meta.env.VITE_SITE_NAME} und ich weiss (fast) alles,
             was auf <1>${import.meta.env.VITE_MAIN_SITE_SHORT}</1> publiziert ist.`,
          },
          from: 'vom',
          question: {
            label: 'Deine Frage',
            placeholder: 'Stelle mir deine Frage',
            buttonText: 'Senden',
            microphoneLabel: 'Frage stellen (mit Mikrofon)',
            microphoneListening:
              'Mikrofon: Halten Sie den Knopf gedrückt und sprechen Sie. Schweizerdeutsch geht auch. Meistens.',
          },
          proposition: {
            intro: 'Stelle deine Frage unten oder starte mit einem dieser Beispiele:',
            example: [
              ['Ich möchte nach Zürich ziehen. Mach mir eine Liste der administrativen Schritte.'],
              ['Was sind die besten Nachtclubs für elektronische Musik in Zürich?'],
              ['Wer ist die Stadtpräsidentin?'],
              ['Wieviele Einwohner hat Zürich?'],
              ['Wann sind Schulferien?'],
              ['Was macht Zürich für den Klimaschutz?'],
              ['Wo lebte Albert Einstein in Zürich?'],
              ['Ist der Strom in Zürich atomfrei?'],
              ['Wo kann ich ein Velo mieten?'],
              ['Ich brauche einen neuen Pass, was kann ich tun?'],
              ['Wann ist die nächste Kartonsammlung an der Quellenstrasse?'],
              ['Ich brauche einen neuen Pass, was kann ich tun?'],
              ['Wie entsorge ich Pneus?'],
              ['Erzähl mir von kreativen Aktivitäten, die ich mit meinen Kindern machen kann.'],
              ['Gib mir Tipps und Tricks, um bei meiner Steuererklärung Geld zu sparen.'],
              ['Wo kann ich einen Betreibungsregisterauszug bestellen?'],
              ['Wann ist Papiersammlung in Wiedikon?'],
              ['Wie kann ich eine Parkkarte bestellen?'],
              ['Ich ziehe nach Zürich, wo muss ich mich anmelden?'],
              ['Wie kriege ich eine Wohnsitzbestätigung?'],
              ['Wie melde ich mein Kind für den Hort an?'],
            ],
          },
          results: {
            initial: `
              Hallo, ich bin ${import.meta.env.VITE_SITE_NAME} und ich weiss – fast – alles, was auf ${
                import.meta.env.VITE_MAIN_SITE_SHORT
              } publiziert ist.
              Frag mich etwas über diese wunderbare Stadt :)`,
            initialEmpty: ``,
          },
          answers: {
            oneMoment: 'Einen Moment bitte...',
            serverConnectionError: 'Verbindung zum Server abgebrochen :( Bitte bald nochmals probieren.',
            otherModelButton: 'Neue Antwort generieren',
            wasItUseful: 'War die Antwort hilfreich?',
            yes: 'Ja',
            no: 'Nein',
            giveFeedback: 'Feedback geben',
            readHints: 'Hinweise und Einschränkungen lesen',
            letsTryToImprove: 'Lass uns versuchen, die Antwort zu verbessern!',
            improvePrompt: 'Frage verfeinern und nochmals probieren',
            thanksForFeedback: 'Danke für Ihr Feedback!',
            unhappy: 'Unzufrieden mit der Antwort?',
            question: 'Frage',
            showRefs: 'Alle Referenzen anzeigen',
            hideRefs: 'Referenzen minimieren',
          },
          hints: {
            title: 'Hinweise und Einschränkungen',
            notesTitle: 'Hinweise',
            note1:
              'Der Bot merkt sich einmal gestellte Fragen nicht. Für Verfeinerungen muss die Frage nochmals eingegeben werden.',
            note2:
              'Es ist besser ganze Fragen zu stellen, anstatt nur Stichwörter. Manchmal hilft es auch eine Frage anders zu formulieren, um eine perfektere Antwort zu erhalten.',
            note3:
              'Oder die Jahreszahl (z.B. 2023) mitzuliefern für aktuellere Antworten. Oder generell spezifischer zu fragen.',
            note4: 'Sollte keine Antwort kommen oder die Antwort abrupt enden, am Besten nochmals probieren.',
            note5:
              'Wir arbeiten kontinuierlich daran den Service zu verbessern. Komm also später wieder vorbei, wenn du noch nicht ganz zufrieden warst. Und darum speichern wir auch deine Fragen und Antworten bei uns, damit wir davon lernen können. Wir freuen uns auch, wenn du uns Feedback gibst, das hilft uns enorm. [Datenschutzerklärung](/privacy)',
            note6:
              'Alle Angaben ohne Gewähr. Bitte überprüfe die Information auf den offiziellen Webseiten der Stadt Zürich.',
            limitationsTitle: 'Einschränkungen',
            limitationsText: `${import.meta.env.VITE_SITE_NAME} weiss (fast) alles, 
            was am ${import.meta.env.VITE_DATE_DE} auf <1>${import.meta.env.VITE_MAIN_SITE_SHORT}</1> publiziert war.`,
            disclaimerText: 'Diese Website ist nicht mit der Stadt Zürich assoziiert.',
          },
          contact: {
            title: 'Kontakt und Feedback',
            intro: `
              Wir arbeiten kontinuierlich daran ${
                import.meta.env.VITE_SITE_NAME
              } zu verbessern, zu erweitern und regelmässig zu aktualisieren.
              Wir freuen uns daher über jedes Feedback. Oder auch einfach weitererzählen, wenn du mit ${
                import.meta.env.VITE_SITE_NAME
              } zufrieden bist. `,
            emailLabel: 'Deine E-Mail (optional):',
            messageLabel: 'Deine Nachricht:',
            submitButton: 'Abschicken',
            feedbackReceived: 'Feedback erhalten, danke!',
            feedbackReceivedError: 'Da ist was schief gelaufen, bitte kontaktiere uns auf anderen Wegen.',
            emailText: 'Oder senden Sie eine Mail an chregu@liip.ch',
            moreContact: 'Mehr Kontaktmöglichkeiten auf',
          },
          footer: {
            credits: 'Powered by <1>Liip</1> — <3>Blog Post über das Projekt</3>',
            disclaimer: `${import.meta.env.VITE_SITE_NAME} ist nicht mit der Stadt Zürich assoziiert.`,
            selectLanguage: 'Sprache auswählen',
          },
        },
      },
      fr: {
        translation: {
          header: {
            title: `${import.meta.env.VITE_SITE_NAME}`,
            slogan: `Je suis ${
              import.meta.env.VITE_SITE_NAME
            } et je connais (presque) tout ce qui a été publié sur <1>${import.meta.env.VITE_MAIN_SITE_SHORT}</1>.`,
          },
          from: 'du',

          results: {
            initial: `
              Bonjour, je suis ${
                import.meta.env.VITE_SITE_NAME
              } et je connais – presque – tout ce qui a été publié sur ${import.meta.env.VITE_MAIN_SITE_SHORT}.
              Posez-moi toutes vos questions sur cette merveilleuse ville :)`,
            initialEmpty: ``,
          },
          question: {
            label: 'Votre Question',
            placeholder: 'Posez-moi votre question',
            buttonText: 'Envoyer',
            microphoneLabel: 'Poser la question (avec microphone)',
            microphoneListening: 'Microphone: Maintenez le bouton enfoncé et parlez',
          },
          proposition: {
            intro: 'Posez votre question ci-dessous ou commencez avec un de ces exemples:',
            example: [
              ['Je veux déménager à Zurich. Fais-moi une liste des étapes administratives.'],
              ['Quels sont les meilleurs clubs nocturnes pour la musique électronique à Zurich?'],
              ['Qui est la maire de la ville?'],
              ["Combien d'habitants Zurich a-t-elle?"],
              ['Quand sont les vacances scolaires?'],
              ['Que fait Zurich pour la protection du climat?'],
              ['Où Albert Einstein habitait-il à Zurich?'],
              ["L'électricité à Zurich est-elle sans nucléaire?"],
              ['Où puis-je louer un vélo?'],
              ["J'ai besoin d'un nouveau passeport, que puis-je faire?"],
              ['Quand est la prochaine collecte de carton à Quellenstrasse?'],
              ["J'ai besoin d'un nouveau passeport, que puis-je faire?"],
              ['Comment je dispose des pneus?'],
              ["Parle-moi d'activités créatives que je peux faire avec mes enfants."],
              ["Donnez-moi des astuces pour économiser de l'argent sur ma déclaration de revenus."],
              ['Où puis-je commander un extrait de registre de poursuites?'],
              ['Quand est la collecte de papier à Wiedikon?'],
              ['Comment puis-je commander une carte de stationnement?'],
              ["Je déménage à Zurich, où dois-je m'enregistrer?"],
              ['Comment puis-je obtenir une confirmation de résidence?'],
              ['Comment inscrire mon enfant à la garderie?'],
            ],
          },
          answers: {
            oneMoment: "Un moment s'il vous plaît...",
            serverConnectionError: 'Connexion au serveur interrompue :( Veuillez réessayer bientôt.',
            otherModelButton: 'Générer une nouvelle réponse',
            wasItUseful: 'La réponse vous était utile?',
            yes: 'Oui',
            no: 'Non',
            giveFeedback: 'Donner un feedback',
            readHints: 'Lire les astuces et limitations',
            letsTryToImprove: 'Essayons d’améliorer les choses!',
            improvePrompt: 'Affiner la question et réessayer',
            thanksForFeedback: 'Merci pour votre feedback!',
            unhappy: 'Insatisfait de la réponse?',
            question: 'Question',
            showRefs: 'Afficher toutes les références',
            hideRefs: 'Minimiser les références',
          },
          hints: {
            title: 'Astuces et Limitations',
            notesTitle: 'Astuces',
            note1: `${
              import.meta.env.VITE_SITE_NAME
            } ne se souvient pas des questions posées. Si la réponse ne vous convient pas, reprenez votre question et affinez-la pour améliorer la qualité de la réponse.`,
            note2:
              'Il est préférable de poser des questions complètes plutôt que simplement des mots-clés. Parfois, reformuler la question peut donner une meilleure réponse.',
            note3:
              "Fournir l'année en cours (par exemple, 2023) ou poser des questions plus spécifiques peut donner des réponses plus à jour.",
            note4: "Si aucune réponse n'est reçue ou si la réponse se termine brusquement, relancez votre recherche.",
            note5:
              "Nous stockons vos questions et réponses chez nous afin de pouvoir améliorer le service au fil du temps. C'est pourquoi nous apprécions si vous nous donnez des retours ; cela nous aide énormément. [Politique de Confidentialité](/privacy)",
            note6:
              'Toutes les informations sont fournies sans garantie. Veuillez vérifier les informations sur le site officiel de la ville de Zurich.',
            limitationsTitle: 'Limitations',
            limitationsText: `${import.meta.env.VITE_SITE_NAME} connaît uniquement ce qui a été publié sur <1>${
              import.meta.env.VITE_MAIN_SITE_SHORT
            }</1> avant le ${import.meta.env.VITE_DATE_FR}.`,
            disclaimerText: "Cette application n'est pas associée à la ville de Zurich.",
          },
          contact: {
            title: 'Contact et Commentaires',
            intro: `
              Nous continuons à travailler dur pour améliorer, agrandir et mettre à jour régulièrement ${
                import.meta.env.VITE_SITE_NAME
              }. 
              Nous apprécions donc tous les retours. Ou même en parler autour de vous si vous êtes satisfait de ${
                import.meta.env.VITE_SITE_NAME
              }. `,
            emailLabel: 'Votre E-Mail :',
            messageLabel: 'Votre message :',
            submitButton: 'Envoyer',
            feedbackReceived: 'Merci pour votre message!',
            feedbackReceivedError: 'Quelque chose s’est mal passé, veuillez nous contacter par d’autres moyens.',
            emailText: 'Ou envoyez un email à chregu@liip.ch',
            moreContact: "Plus d'options de contact sur",
          },
          footer: {
            credits: 'Propulsé par <1>Liip</1> — <3>Article sur le projet</3>',
            disclaimer: `${import.meta.env.VITE_SITE_NAME} n'est pas affilié à la ville de Zurich.`,
            selectLanguage: 'Sélectionner la Langue',
          },
        },
      },
    },
  })

export default i18n
