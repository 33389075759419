import { t } from 'i18next'
import { useState } from 'react'

import { ArrowRight } from '../icons/ArrowRight'

const getRandomIndexes = <T extends any>(arr: T[], n: number): number[] => {
  if (!Array.isArray(arr) || n <= 0 || n > arr.length) {
    return []
  }

  const indexes = new Set<number>()

  while (indexes.size < n) {
    const randomIndex = Math.floor(Math.random() * arr.length)
    indexes.add(randomIndex)
  }

  return [...indexes]
}

export const InitialPropositions = (props: { sendSubmit: (value: string) => void }) => {
  const propositions = t('proposition.example', { returnObjects: true }) as string[][]
  const [indexes] = useState<number[]>(getRandomIndexes(propositions, 4))
  const [finalPropositions] = useState<string[]>(
    indexes.map(index => {
      return propositions[index][getRandomIndexes(propositions[index], 1)[0] as number]
    }),
  )
  return (
    <>
      <h3 className="p">{t('proposition.intro')}</h3>
      <div className="propositions">
        {finalPropositions.map((finalProposition: string, index) => (
          <button key={index} className="button button--card" onClick={() => props.sendSubmit(finalProposition)}>
            <span>{finalProposition}</span>
            <ArrowRight />
          </button>
        ))}
      </div>
    </>
  )
}
